import {Directive, TemplateRef, ViewContainerRef} from '@angular/core';
import {CanaryService} from './canary.service';

@Directive({standalone: true, selector: '[canary]'})
export class CanaryDirective {
  constructor(canaryService: CanaryService, templateRef: TemplateRef<any>, viewContainer: ViewContainerRef) {
    if (canaryService.enabled) viewContainer.createEmbeddedView(templateRef);
    else viewContainer.clear();
  }
}
