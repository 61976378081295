import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class CanaryService {
  constructor(private router: Router) {
  }

  get enabled(): boolean {
    return !!localStorage.getItem('canary');
  }

  disable() {
    localStorage.removeItem('canary');
    this.router.navigate([]).then(() =>
      setTimeout(() => location.reload(), 500)
    );
  }

  enable() {
    localStorage.setItem('canary', 'true');
    this.router.navigate([]).then(() =>
      setTimeout(() => location.reload(), 500)
    );
  }
}
