import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {gitVersion} from '../version';

@Injectable({providedIn: 'root'})
export class ServerVersionService extends BehaviorSubject<{ server?: string, client: string }> {

  constructor() {
    super(({client: gitVersion}));
  }

  public update(server?: string) {
    super.next({server, client: gitVersion});
  }
}
